<template>
    <div>
      <coupon-comp :type="type"></coupon-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import SliderComp from "@/components/admin/slider/SliderComp";
import CouponComp from "@/components/admin/coupon/CouponComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "create-coupon",
    components : {CouponComp, SliderComp},

    setup() {

        const type = ref('create');
        onMounted(() => {
            setCurrentPageBreadcrumbs("Create Coupon", ["Coupons"] , checkPermission('coupons.store') ? 'create-coupon': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
